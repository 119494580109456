<template>
  <v-list-item
    v-if="createNode"
    :node-id="item.id"
    :node="label"
    eagle-tree="node"
    :is-moving-node="isMovingNode"
  >
    <v-list-item-content>
      <v-list-item-title>{{ label }}</v-list-item-title>
    </v-list-item-content>

    <move
      :item="item"
      :treeKey="treeKey"
    ></move>

    <check
      :item="item"
      :treeKey="treeKey"
    ></check>

    <createChild
      :item="item"
      :treeKey="treeKey"
    ></createChild>

    <edit
      :item="item"
      :treeKey="treeKey"
    ></edit>

    <delete
      :item="item"
      :treeKey="treeKey"
    ></delete>
  </v-list-item>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    item: Object,
    open: Boolean,
    treeKey: String,
  },
  computed: {
    createNode() {
      if(this.max == 0) return true
      return this.item.depth <= this.max
    },
    isMovingNode() {
      if(!this.hasMoveNode) return false
      return this.item.id == this.moveNode
    },
    moveNode() {
      return this.$store.getters[`tree/${this.treeKey}/moveNode`]
    },
    hasMoveNode() {
      return this.$store.getters[`tree/${this.treeKey}/hasMoveNode`]
    },
    max() {
      return this.treeConfig.max
    },
    treeConfig() {
      return this.$store.getters[`tree/${this.treeKey}/config`]
    },
    label() {
      return this.treeConfig.label(this.item)
    },
  },
  components: {
    move: () => import('@/components/tree/nodeAction/move.vue'),
    check: () => import('@/components/tree/nodeAction/check.vue'),
    createChild: () => import('@/components/tree/nodeAction/createChild.vue'),
    edit: () => import('@/components/tree/nodeAction/edit.vue'),
    delete: () => import('@/components/tree/nodeAction/delete.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
@import '~assets/sass/variable.sass'
@import '~vuetify/src/styles/styles.sass'
*[eagle-tree="node"]
  border: 1px transparent solid
  &[is-moving-node]
    border: 1px map-get($orange, lighten-2) solid
    border-radius: 5px
</style>